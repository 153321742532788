<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <!--        <router-link to="/orders" class="back-to">-->
        <!--          <em class="icon ni ni-chevron-left-c mr-1"></em>-->
        <!--          <span>Back To Orders Input</span>-->
        <!--        </router-link>-->
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h3 class="ng-block-title fw-normal">
            Week {{GetWeek(start)}} - Forescast Summary
          </h3>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <a
                href="#pickDates"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  Week
                  <span class="d-none d-sm-inline-block">
                    {{GetWeek(start)}}
                  </span>
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="pickDates">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">Select Start Date</h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="start" />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Starting on</h5>
                        <p>
                          {{ fD(start) }}
                        </p>

                        <h5>Ending on</h5>
                        <p>
                          {{ fD(end) }}
                        </p>

                        <button
                          class="btn btn-outline-primary float-right"
                          data-dismiss="modal"
                        >
                          <span> Use Selected Week </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div v-else>
        <div>
          <div class="card" v-for="(varieties, key) in picks" :key="key">
            <div class="card-content">
              <div class="my-4 border-bottom border-4">
                <div class="d-flex justify-content-between m-4">
                  <div>
                    <h4>Product Name: {{ varieties.variety?.name }}</h4>
                  </div>
                  <div>
                    <h6 class="py-1">
                      Projected: {{ addCommas(varieties.total?.projected) }}
                    </h6>
                  </div>
                </div>
                <div>
                  <div class="row justify-content-between mb-5 ml-2">
                    <table class="table table-tranx table-bordered col-5">
                      <tfoot class="tb-tnx-head">
                        <tr class="tb-tnx-item">
                          <th class="tb-tnx-info align-middle">
                            <h6 class="text-left">Amended Projection</h6>
                          </th>
                          <th class="text-center align-middle">
                            {{ addCommas(varieties.total?.ammended) }}
                          </th>
                        </tr>
                        <tr class="tb-tnx-item">
                          <th class="tb-tnx-info align-middle">
                            <h6 class="text-left">WhatsApp Declared</h6>
                          </th>
                          <th class="text-center align-middle">
                            {{ addCommas(varieties.total?.declared) }}
                          </th>
                        </tr>
                        <tr class="tb-tnx-item">
                          <th class="tb-tnx-info align-middle">
                            <h6 class="text-left">Delivery Note Weight</h6>
                          </th>
                          <th class="text-center align-middle">
                            {{ addCommas(varieties.total?.dnote) }}
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                    <table class="table table-tranx table-bordered col-5">
                      <tfoot class="tb-tnx-head">
                        <tr class="tb-tnx-item">
                          <th class="tb-tnx-info align-middle">
                            <h6 class="text-left">Intake Weight</h6>
                          </th>
                          <th class="text-center align-middle">
                            {{ addCommas(varieties.total?.intake) }}
                          </th>
                        </tr>
                        <tr class="tb-tnx-item">
                          <th class="tb-tnx-info align-middle">
                            <h6 class="text-left">Farm Packout</h6>
                          </th>
                          <th class="text-center align-middle">
                            {{ addCommas(varieties.total?.fpackout) }}
                          </th>
                        </tr>
                        <tr class="tb-tnx-item">
                          <th class="tb-tnx-info align-middle">
                            <h6 class="text-left">Packhouse Packout</h6>
                          </th>
                          <th class="text-center align-middle">
                            {{ addCommas(varieties.total?.tpackout) }}
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <table
                    class="table table-tranx table-bordered"
                    v-if="varieties.picks.length > 0"
                  >
                    <thead class="tb-tnx-head">
                      <tr class="tb-tnx-item">
                        <th class="tb-tnx-info">Farm Name</th>
                        <th class="tb-tnx-info">Variety Name</th>
                        <th class="tb-tnx-info">Amended Projection</th>
                        <th class="tb-tnx-info">WhatsApp Declared</th>
                        <th class="tb-tnx-info">Delivery Note Weight</th>
                        <th class="tb-tnx-info">Intake Weight</th>
                        <th class="tb-tnx-info">Farm Packout</th>
                        <th class="tb-tnx-info">Packhouse Packout</th>
                        <th class="tb-tnx-info">Projected</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="tb-tnx-item"
                        v-for="(pick, key) in varieties.picks"
                        :key="key"
                      >
                        <td class="tb-tnx-info align-middle">
                          {{ pick.farm_name }}
                        </td>
                        <td class="tb-tnx-info align-middle">
                          {{ pick.variety_name }}
                        </td>
                        <td class="tb-tnx-info align-middle">
                          {{ addCommas(pick.ammended) }}
                        </td>
                        <td class="tb-tnx-info align-middle">
                          {{ addCommas(pick.declared) }}
                        </td>
                        <td class="tb-tnx-info align-middle">
                          {{ addCommas(pick.dnote) }}
                        </td>
                        <td class="tb-tnx-info align-middle">
                          {{ addCommas(pick.intake) }}
                        </td>
                        <td class="tb-tnx-info align-middle">
                          {{ addCommas(pick.fpackout) }}
                        </td>
                        <td class="tb-tnx-info align-middle">
                          {{ addCommas(pick.tpackout) }}
                        </td>
                        <td class="tb-tnx-info align-middle">
                          {{ addCommas(pick.projected) }}
                        </td>
                      </tr>
                    </tbody>
                    <!--                                  <tfoot class="tb-tnx-head d-print-none">-->
                    <!--                                    <tr class="tb-tnx-item">-->
                    <!--                                      <th class="tb-tnx-info" colspan="8">-->
                    <!--                                        <h6 class="text-center">TOTAL</h6>-->
                    <!--                                      </th>-->
                    <!--                                      <th>{{ sum(details.orders, "quantity") }}</th>-->
                    <!--                                      <th>{{ sum(details.orders, "total_weight") }} Kg</th>-->
                    <!--                                      <th></th>-->
                    <!--                                      <th>-->
                    <!--                                        {{ details.customer.contact?.currency_symbol }}-->
                    <!--                                        {{ sum(details.orders, "total_cost") }}-->
                    <!--                                      </th>-->
                    <!--                                    </tr>-->
                    <!--                                  </tfoot>-->
                  </table>
                  <div class="alert alert-warning text-center" v-else>
                    <h5>
                      <em class="icon ni ni-alert text-warning"></em>
                    </h5>
                    There is no summary for this product
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import moment from "moment";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  props: {
    day: {
      type: String
    }
  },
  setup(props) {
    const picks = ref({});
    const farms = ref({});

    const processing = ref(false);

    function friday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 4));
    }

    const s = props.day ? new Date(props.day) : friday();
    const start = ref(s);
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function getYear(dte: any) {
      return format(dte, "Y");
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = value.toFixed(2);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    function sum(items: any[], key: string | number) {
      const summation = items.reduce(function(a: any, b: { [x: string]: any }) {
        return a + b[key];
      }, 0);
      return addCommas(summation);
    }

    function fetchWeekPicks() {
      processing.value = true;
      http
        .get(
          `/api/weekly-picks-summary?start=${fD(
            start.value,
            "yyyy-MM-dd"
          )}&end=${fD(end.value, "yyyy-MM-dd")}`
        )
        .then(res => {
          picks.value = res.data.picks;
          farms.value = res.data.farms;
          processing.value = false;
        });
    }

    onMounted(() => {
      fetchWeekPicks();
    });

    watch(
      () => start.value,
      (v: any) => {
        fetchWeekPicks();
      }
    );

    return {
      processing,
      fD,
      getDates,
      start,
      end,
      getYear,
      picks,
      sum,
      farms,
      addCommas
    };
  }
});
</script>
